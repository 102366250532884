<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Pengajuan Catatan</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2" id="card-custom">
            <template v-if="!isUserRole">
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units"
                  v-model="unit"
                  :loading="loadingUnit"
                  placeholder="Unit Utama"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_utama_id"
                  clearable
                  @change="changeUnit(1)"
                  @click:clear="changeUnit(1)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units2"
                  v-model="unit2"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_2_id"
                  clearable
                  @change="changeUnit(2)"
                  @click:clear="changeUnit(2)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units3"
                  v-model="unit3"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_3_id"
                  clearable
                  @change="changeUnit(3)"
                  @click:clear="changeUnit(3)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units4"
                  v-model="unit4"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_4_id"
                  clearable
                ></v-autocomplete>
              </v-col>
            </template>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                placeholder="Jenis Catatan"
                :items="types"
                v-model="note_id"
                outlined
                dense
                hide-details
                clearable
                item-text="note_ket"
                item-value="note_id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    placeholder="Bulan"
                    append-icon="event"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  type="month"
                  locale="id"
                  no-title
                  scrollable
                  @input="menu = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-text-field
                v-model="search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row id="card-custom">
            <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
              <v-card-actions>
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="handleCreate"
                  >Tambah Catatan<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
                <div v-if="!isUserRole">
                  <v-btn
                    text
                    class="text-capitalize caption headline-color"
                    color="error"
                    @click="$refs.catatanDeleteBulk.createBulk()"
                    >Hapus Catatan<v-icon class="ml-2" color="error"
                      >highlight_off</v-icon
                    ></v-btn
                  >
                </div>
              </v-card-actions>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
              <v-card-actions class="pa-0">
                <v-spacer />
                <v-btn color="#FBB005" class="px-10" @click="getListNote">
                  <span class="subtitle-2 text-capitalize">Cari</span>
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-divider />
          <v-data-table
            id="table-custom"
            class="mt-2"
            multi-sort
            :headers="headers"
            :items="notes"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
            item-key="no"
          >
            <template v-slot:[`item.trans_date`]="{ item }">
              {{ item.trans_date | date }}
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <span v-if="item.created_at">{{
                item.created_at.date | dateTime
              }}</span>
            </template>
            <template v-slot:[`item.NIP`]="{ item }">
              <div class="py-1">
                <p class="mb-0 font-weight-bold">{{ item.NIP }}</p>
                <p class="mb-0 font-weight-bold">{{ item.nama }}</p>
                <p class="mb-0">{{ item.unit_kerja_2 }}</p>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="handleEdit(item)"
                >Edit</v-btn
              >
              <v-btn
                x-small
                color="#FBB005"
                class="mr-2 my-1 white--text"
                @click="handleDelete(item)"
                >Hapus</v-btn
              >
            </template>
            <template v-slot:no-data>
              <ContentNotFound message="Data pengajuan catatan tidak ada" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <CatatanBulkDialog
      :single="!isMultiple"
      ref="catatanBulk"
      @on-refresh="getListNote"
    />
    <CatatanDeleteBulkDialog
      ref="catatanDeleteBulk"
      @on-refresh="getListNote"
    />
  </div>
</template>
<script>
const ContentNotFound = () => import("@/components/Content/NotFound");
const CatatanBulkDialog = () => import("@/components/Dialog/CatatanBulk");
const CatatanDeleteBulkDialog = () =>
  import("@/components/Dialog/CatatanDeleteBulk");
import DataUtamaService from "@/services/resources/data.utama.service";
import AttendanceService from "@/services/resources/attendance.service";
import PublicService from "@/services/resources/public.service";
import { mapGetters } from "vuex";

export default {
  components: {
    ContentNotFound,
    CatatanBulkDialog,
    CatatanDeleteBulkDialog
  },
  data() {
    return {
      search: null,
      note_id: null,
      loadingUnit: false,
      loadingType: false,
      menu: false,
      date: new Date().toISOString().substr(0, 7),
      unit: null,
      unit2: null,
      unit3: null,
      unit4: null,
      units: [],
      units2: [],
      units3: [],
      units4: [],
      statusList: ["NEW", "APPROVED", "REJECTED", "CANCELLED"],
      types: [],
      headers: [
        {
          text: "TANGGAL",
          value: "trans_date",
          sortable: false,
          align: "left"
        },
        { text: "NIP", value: "NIP", sortable: false, align: "left" },
        { text: "CATATAN", value: "note_ket", sortable: false, align: "left" },
        { text: "DIBUAT", value: "created_at", sortable: false, align: "left" },
        { text: "", value: "actions", sortable: false, align: "left" }
      ],
      isMultiple: false,
      isArchive: false,
      selected: [],
      selection: true,
      totalItem: 0,
      pageCount: 0,
      loading: true,
      options: {
        sortBy: ["trans_date"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      notes: []
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isUserRole() {
      return this.currentUser.role === "USER";
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListNote);
      },
      deep: true
    }
  },
  methods: {
    handleCreate() {
      if (this.isUserRole) {
        this.isMultiple = false;
        this.$refs.catatanBulk.changeSingle([
          {
            NIP: this.currentUser.NIP,
            name: this.currentUser.name
          }
        ]);
      } else {
        this.isMultiple = true;
        this.$refs.catatanBulk.change(true);
      }
    },
    handleEdit(item) {
      this.isMultiple = true;
      this.$refs.catatanBulk.editSingle({
        trans_id: item.trans_id,
        NIP: item.NIP,
        name: item.nama,
        date: item.trans_date
      });
    },
    changeUnit(type = 2) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    handleDelete(item) {
      this.$confirm({
        title: "Hapus Catatan",
        message: `Apakah Anda yakin akan menghapus catatan ini?`,
        button: {
          no: "Batal",
          yes: "Hapus"
        },
        isRemark: true,
        callback: (confirm, remark) => {
          if (confirm) {
            let payload = {
              NIP: [item.NIP],
              date_start: item.trans_date,
              date_end: item.trans_date,
              remark
            };
            this.deleteNote(payload);
          }
        }
      });
    },
    handleApprove(item, status = "APPROVE") {
      let action,
        title = null;
      if (status == "APPROVE") {
        title = "Setujui";
        action = 100001;
      } else if (status == "REJECT") {
        title = "Menolak";
        action = 100002;
      } else if (status == "CANCEL") {
        title = "Membatalkan";
        action = 100003;
      }
      this.$confirm({
        title: `${title} Catatan`,
        message: `Apakah Anda yakin akan ${title} catatan ini?`,
        button: {
          no: "Batal",
          yes: `${title}`
        },
        callback: confirm => {
          if (confirm) {
            let formData = new FormData();
            formData.append("trans_id", item.trans_id);
            formData.append("action", action);
            this.updateStatusNote(formData);
          }
        }
      });
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListJenisCatatan() {
      try {
        this.loadingType = true;
        await PublicService.jenisCatatanGetList()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.types = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingType = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListNote() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await AttendanceService.getListNoteReal({
          filter: {
            search: this.search,
            note_id: this.note_id,
            unit_utama_id: this.unit,
            unit_kerja_2_id: this.unit2,
            unit_kerja_3_id: this.unit3,
            unit_kerja_4_id: this.unit4
          },
          period: this.$moment(this.date).format("YYYYMM"),
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let notes = list;
              notes.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.notes = notes;
              this.totalItem = parseInt(countTotal);
              this.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteNote(payload) {
      try {
        this.formLoading = true;
        await AttendanceService.deleteNote(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListNote();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async updateStatusNote(payload) {
      try {
        this.formLoading = true;
        await AttendanceService.updateStatusNote(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListNote();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnit();
    this.getListJenisCatatan();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #f5f6fa;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    color: #a3a6b4;
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
</style>
