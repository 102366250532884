import BaseInstance from "../base.instance";
import {
  ATTENDANCE_LIST,
  ATTENDANCE_FORCE_UPDATE,
  RECAPITULATION_LIST,
  ATTENDANCE_REQUEST_OFF,
  ATTENDANCE_REQUEST_OFF_LIST,
  ATTENDANCE_REQUEST_OFF_BULK,
  ATTENDANCE_REQUEST_OFF_STATUS,
  ATTENDANCE_REQUEST_OFF_DELETE,
  PRESENSI_ATTENDANCE_DAFTAR_SCHEDULE,
  PRESENSI_ATTENDANCE_GENERATE_ATTENDANCE,
  ATTENDANCE_CHECK_IN_WEB,
  ATTENDANCE_CHECK_OUT_WEB,
  PRESENSI_ABSENSI_WEB,
  PRESENSI_LOG_ABSENSI,
  PRESENSI_SAVE_ACTIVITY,
  PRESENSI_LIST_SKIP,
  PRESENSI_SAVE_SKIP,
  PRESENSI_DELETE_SKIP,
  PRESENSI_NOTE_LIST_REAL,
  PRESENSI_NOTE_LIST_PENDING,
  PRESENSI_NOTE_LIST_HISTORY,
  PRESENSI_NOTE_DETAIL_DATA,
  PRESENSI_NOTE_SAVE_DATA,
  PRESENSI_NOTE_DELETE_DATA,
  PRESENSI_NOTE_UPDATE_STATUS
} from "../constants";

const AttendanceService = {
  getList(data) {
    return BaseInstance.post(ATTENDANCE_LIST, data);
  },
  forceUpdate(data) {
    return BaseInstance.post(ATTENDANCE_FORCE_UPDATE, data);
  },
  recapitulationList(data) {
    return BaseInstance.post(RECAPITULATION_LIST, data);
  },
  // Request Update
  getListNoteReal(data) {
    return BaseInstance.post(PRESENSI_NOTE_LIST_REAL, data);
  },
  getListNotePending(data) {
    return BaseInstance.post(PRESENSI_NOTE_LIST_PENDING, data);
  },
  getListNoteHistory(data) {
    return BaseInstance.post(PRESENSI_NOTE_LIST_HISTORY, data);
  },
  saveNote(data) {
    return BaseInstance.post(PRESENSI_NOTE_SAVE_DATA, data);
  },
  detailNote(id) {
    return BaseInstance.fetch(PRESENSI_NOTE_DETAIL_DATA, id);
  },
  deleteNote(data) {
    return BaseInstance.post(PRESENSI_NOTE_DELETE_DATA, data);
  },
  updateStatusNote(data) {
    return BaseInstance.post(PRESENSI_NOTE_UPDATE_STATUS, data);
  },
  // Request Off
  getListRequestOff(data) {
    return BaseInstance.post(ATTENDANCE_REQUEST_OFF_LIST, data);
  },
  saveRequestOff(data) {
    return BaseInstance.post(ATTENDANCE_REQUEST_OFF, data);
  },
  saveBulkRequestOff(data) {
    return BaseInstance.post(ATTENDANCE_REQUEST_OFF_BULK, data);
  },
  detailRequestOff(id) {
    return BaseInstance.fetch(ATTENDANCE_REQUEST_OFF, id);
  },
  updateStatusRequestOff(data) {
    return BaseInstance.post(ATTENDANCE_REQUEST_OFF_STATUS, data);
  },
  deleteRequestOff(data) {
    return BaseInstance.post(ATTENDANCE_REQUEST_OFF_DELETE, data);
  },
  getListAttendance(data) {
    return BaseInstance.post(PRESENSI_ATTENDANCE_DAFTAR_SCHEDULE, data);
  },
  generateAttendance(data) {
    return BaseInstance.post(PRESENSI_ATTENDANCE_GENERATE_ATTENDANCE, data);
  },
  checkIn(data) {
    return BaseInstance.post(ATTENDANCE_CHECK_IN_WEB, data);
  },
  checkOut(data) {
    return BaseInstance.post(ATTENDANCE_CHECK_OUT_WEB, data);
  },
  absensiWeb(data) {
    return BaseInstance.post(PRESENSI_ABSENSI_WEB, data);
  },
  logAbsensi(data) {
    return BaseInstance.post(PRESENSI_LOG_ABSENSI, data);
  },
  saveActivity(data) {
    return BaseInstance.post(PRESENSI_SAVE_ACTIVITY, data);
  },
  listSkip(data) {
    return BaseInstance.post(PRESENSI_LIST_SKIP, data);
  },
  saveSkip(data) {
    return BaseInstance.post(PRESENSI_SAVE_SKIP, data);
  },
  deleteSkip(data) {
    return BaseInstance.post(PRESENSI_DELETE_SKIP, data);
  }
};

export default AttendanceService;
